import React from 'react';
import { style, media, stylesheet, extend } from 'typestyle';
import { border, em, px, background, percent, rgb, padding, rem, rgba, linearGradient, rad } from 'csx';
import { WeewxProtobufData } from '../comm/packets';
import { objToStrMap, ExtremesData } from './RootView';
import { WindCompass } from '../components/winddirection'
import { Alert, GetExtremesDataOut, GetLoopinDataOut, GetSolarDataOut } from '../wxsite';
import dayjs from "dayjs";
import { FormatFtoC, getBackground, formatDayLength, formatEpochDelta, formatSolarElevation, formatSolarEvent, suffixFormatter } from "./utils"
import { DefaultButton, Dialog, Stack } from '@fluentui/react';
import { WXSITEAPI } from '../App';

const css = stylesheet({
    root: {
        position: "fixed", left: -5, right: -15, top: -5, bottom: -15,
        margin: 0, padding: 0,
        fontFamily: "palatino linotype, seirf",
        overflow: "scroll"
    },
    contentHolder: {
        display: "grid",
        gridTemplateColumns: "8rem auto",
        gridTemplateRows: "auto auto",
        width: percent(100),
    },
    header: {
        gridColumnStart: "span 2",
        fontSize: "24pt",
        textAlign: "center",
        marginBottom: em(1),
        padding: em(1),
        fontWeight: 500,
        backgroundColor: rgba(255, 255, 255, 0.3).toString(),
        boxSizing: "border-box"
    },
    tempContainer: {
        gridRow: 2,
    },
    detailContainerColumnContainer: {
        display: "flex",
    },
    detailContainerColumn: {
        minWidth: em(25),
    },
    detailContainer: {
        gridRow: 2,
        backgroundColor: rgba(255, 255, 255, 0.3).toString(),
        marginLeft: rem(4),
        padding: rem(1),
        boxSizing: "border-box",
        marginBottom: rem(2),
    },
    sideTitle: {
        marginTop: em(0.75),
        fontStyle: "italic",
        fontWeight: 600,
    },
    windSpeed:{
        fontSize: '14pt'
    },
    sideStat: {
        fontSize: '24pt'
    },
    detailTable: {
        display: "table"
    },
    detailRow: {
        display: "table-row",
        height: px(18),
    },
    detailCell: {
        display: "table-cell",
        paddingRight : rem(1),
        height: px(20),
    },
    detailCellContents: {
        height: px(30),
        position: "absolute",
    },
    innerTempContainer: {
        backgroundColor: rgba(255, 255, 255, 0.3).toString(),
        boxSizing: "border-box",
        width: rem(10),
        padding: rem(1),
    },
    alertContainer: {
        fontSize: '14pt',
    },
    alertEntry: {
        border: border({color: 'white', width: '1px', style: 'solid'}),
        background: 'linear-gradient( to top, #911, #500)',
        margin: rem(0.25),
        color: 'white',
        padding: rem(0.1),
    },
    preformatedAlertText: {
        minWidth: rem(30),
        whiteSpace: "pre-line",
        fontFamily: "monospace",
        marginBottom: rem(1),
    }
})

interface DesktopViewProps {
    weewxData?: WeewxProtobufData
    extremesData?: ExtremesData
    solarData?: GetSolarDataOut
    loopinData?: GetLoopinDataOut
    alerts?: Alert[]
}


const dualUnitMeasurement = (weewxData: (WeewxProtobufData | undefined), key: string): [number | undefined, number | undefined] => {
    if (weewxData === undefined) {
        return [undefined, undefined]
    }
    const
        imperial = weewxData.imperial,
        metric = weewxData.metric


    const 
        impMap = objToStrMap(JSON.parse(JSON.stringify(imperial))),
        metMap = objToStrMap(JSON.parse(JSON.stringify(metric))),
        impValue = impMap.get(key),
        metValue = metMap.get(key)

    return [impValue, metValue]
}

const dualTemperature = (weewxData: (WeewxProtobufData | undefined), key: string): JSX.Element => {
    const [imp, met] = dualUnitMeasurement(weewxData, key)

    return <>{imp?.toFixed(1)}&deg; F / {met?.toFixed(1)}&deg; C</>
}

const windSpeed = (weewxData: (WeewxProtobufData | undefined), key: string, dirKey: string): JSX.Element => {
    if (weewxData?.imperial.windGust === 0) {
        return <>Calm</>
    }
    const 
        [imp, met] = dualUnitMeasurement(weewxData, key),
        [impDir, metDir] = dualUnitMeasurement(weewxData, dirKey)

    return <>{imp?.toFixed(0)}mph / {met?.toFixed(1)}km/h - {impDir}&deg;</>
}

const dualRain = (weewxData: (WeewxProtobufData | undefined), key: string): JSX.Element => {
    const 
        [imp, met] = dualUnitMeasurement(weewxData, key)

    return <>{imp?.toFixed(0)} in/hr / {met?.toFixed(1)} mm/hr</>
}



export const DesktopView = 
    ({weewxData, solarData, extremesData, loopinData, alerts}: DesktopViewProps) => {

        const
            [useMetric, setUseMetric] = React.useState(false),
            [weewxAge, setWeewxAge] = React.useState("N/A"),
            [alertVisible, setAlertVisible] = React.useState(false),
            [currentAlert, setCurrentAlert] = React.useState<Alert | undefined>(undefined)
        

        

        React.useEffect(() => {
            if (currentAlert === undefined) {
                return
            }
            setAlertVisible(true)
        }, [currentAlert])

        React.useEffect(() => {
            const timer = setInterval(() => {
                if (weewxData === undefined) {
                    setWeewxAge("N/A")
                } else {
                    setWeewxAge(formatEpochDelta(weewxData.timestamp))
                }
                
            }, 250)
            return () => {
                clearInterval(timer)
            }
        }, [weewxData])

        const 
            bg: string = (weewxData !== undefined) ?
                (
                    getBackground(weewxData)
                ) : "linear-gradient( to top, #DDD, #111)",
            extremeTime = (() => {
                if (extremesData === undefined || extremesData === null) {
                    return "N/A"
                }
                const dt = new Date(extremesData.imperial.lastFifteen.epochEnd * 1000)
                return dt.toLocaleTimeString()
            })(),
            toTitleCase = (str: string | undefined) => {
                if (str === undefined) {
                    return ""
                }
                return str.replace(
                  /\w\S*/g,
                  function(txt) {
                    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
                  }
                );
              },
            formatAlerts = () => {
                if (alerts === undefined) {
                    return undefined
                }
                if (alerts.length === 0) {
                    return undefined
                }
                return (
                    <div className={css.alertContainer}>
                        {alerts.map((a) => {
                            return (
                                <div 
                                    key={a.systemid}
                                    className={css.alertEntry}
                                    onClick={() => {
                                        setCurrentAlert(a)
                                    }}>
                                    {toTitleCase(a.title)}
                                </div>
                            )
                        })}
                    </div>
                    
                )
            }
        

        return (
            <div className={css.root + " " + style({background: bg})}>
                <div className={css.contentHolder}>
                    <div className={css.header}>
                            Montague Township Weather
                            {formatAlerts()}
                    </div>
                    {
                        <Dialog
                            hidden={!alertVisible}
                            dialogContentProps={{
                                showCloseButton: true,
                                title: toTitleCase(currentAlert?.title),
                                
                            }}
                            onDismiss={() => setAlertVisible(false)}
                            modalProps={{
                                onDismissed: () => {
                                    setAlertVisible(false)
                                    setCurrentAlert(undefined)
                                }
                            }}
                            minWidth="80%"
                        >
                            {
                                currentAlert && currentAlert.effective > 0 &&
                                <><b>Effective: </b> {new Date(currentAlert.effective*1000).toString()}<br /></>
                            }
                            {
                                currentAlert && currentAlert.expiry > 0 &&
                                <><b>Expires: </b> {new Date(currentAlert.expiry*1000).toString()}<br /></>
                            }
                            {
                                currentAlert && currentAlert.sendername &&
                                <><b>From: </b> {currentAlert.sendername}</>
                            }
                            <div>
                                
                            </div>
                            <div className={css.preformatedAlertText}>
                                {currentAlert?.description}<br />
                                {currentAlert?.instruction}
                            </div>
                            <DefaultButton
                                onClick={() => setAlertVisible(false)}
                            >Close</DefaultButton>
                        </Dialog>
                    }
                    

                    <div className={css.tempContainer}>
                        <div className={css.innerTempContainer}>
                            <div className={css.sideTitle}>Air Temp</div>
                            {
                                loopinData ?
                                <div className={css.sideStat}>{loopinData.outtemp.toFixed(1)}&deg; F</div>
                                : <div className={css.sideStat}>{weewxData?.imperial.outsideTemp.toFixed(1)}&deg; F</div>
                            }
                            
                            {
                                loopinData ?
                                <div className={css.sideStat}>{FormatFtoC(loopinData.outtemp)}&deg; C</div>
                                : <div className={css.sideStat}>{weewxData?.metric.outsideTemp.toFixed(1)}&deg; C</div>
                            }
                            

                            <div className={css.sideTitle}>Feels Like</div>
                            <div className={css.sideStat}>{weewxData?.imperial.feelsLike.toFixed(1)}&deg; F</div>
                            <div className={css.sideStat}>{weewxData?.metric.feelsLike.toFixed(1)}&deg; C</div>

                            {
                                loopinData ? 
                                <WindCompass windSpeed={loopinData.windspeed} windGust={loopinData.windgust} windDirection={loopinData.windDir} windGustDirection={loopinData.windgustdir} />
                                : <WindCompass windSpeed={weewxData?.imperial.windSpeed} windGust={weewxData?.imperial.windGust} windDirection={weewxData?.imperial.windDir} windGustDirection={weewxData?.imperial.windGustDir} />
                            }

                            <div className={css.sideTitle}>Max Gust</div>
                            <div className={css.windSpeed}>15m: {extremesData?.imperial.lastFifteen.maxGust}mph</div>
                            <div className={css.windSpeed}>Day: {extremesData?.imperial.lastHour.maxGust}mph</div>
                            <div className={css.windSpeed}>Week: {extremesData?.imperial.lastWeek.maxGust}mph</div>
                            <div className={css.windSpeed}>Month: {extremesData?.imperial.lastMonth.maxGust}mph</div>
                            
                        </div>
                    </div>

                    <div className={css.detailContainer}>
                        <div className={css.detailContainerColumnContainer}>
                            <div className={css.detailContainerColumn}>
                                <div className={css.detailTable}>
                                    <div className={css.detailRow}>
                                        <div className={css.detailCell}>Last Update</div>
                                        <div className={css.detailCell}>{weewxAge}</div>
                                    </div>

                                    <div className={css.detailRow}>
                                        <div className={css.detailCell}>Time Of Day</div>
                                        <div className={css.detailCell}>{solarData?.timeOfDay || "N/A"}</div>
                                    </div>
                                    <div className={css.detailRow}>
                                        <div className={css.detailCell}>Outside Humidity</div>
                                        <div className={css.detailCell}>{weewxData?.metric.outsideHumidity.toFixed(0)}% RH</div>
                                    </div>
                                    <div className={css.detailRow}>
                                        <div className={css.detailCell}>Heat Index</div>
                                        <div className={css.detailCell}>{dualTemperature(weewxData, "heatIndex")}</div>
                                    </div>
                                    <div className={css.detailRow}>
                                        <div className={css.detailCell}>Dew Point</div>
                                        <div className={css.detailCell}>{dualTemperature(weewxData, "dewPoint")}</div>
                                    </div>
                                    <div className={css.detailRow}>
                                        <div className={css.detailCell}>Barometric Pressure</div>
                                        <div className={css.detailCell}>{weewxData?.metric.pressure.toFixed(3)} kPa</div>
                                    </div>
                                    <div className={css.detailRow}>
                                        <div className={css.detailCell}>Irradiance</div>
                                        <div className={css.detailCell}><div className={css.detailCellContents}>{weewxData?.metric.rad} W/m<sup>2</sup></div></div>
                                    </div>
                                    <div className={css.detailRow}>
                                        <div className={css.detailCell}>Wind Speed</div>
                                        <div className={css.detailCell}>{windSpeed(weewxData, "windSpeed", "windDir")}</div>
                                    </div>
                                    <div className={css.detailRow}>
                                        <div className={css.detailCell}>Wind Gust</div>
                                        <div className={css.detailCell}>{windSpeed(weewxData, "windGust", "windGustDir")}</div>
                                    </div>
                                    <div className={css.detailRow}>
                                        <div className={css.detailCell}>Rain Rate</div>
                                        <div className={css.detailCell}>{dualRain(weewxData, "rainRate")}</div>
                                    </div>
                                    <div className={css.detailRow}>
                                        <div className={css.detailCell}>Official Sunrise/Sunset</div>
                                        <div className={css.detailCell}>{formatSolarEvent(solarData?.officialSunrise, solarData?.officialSunset)}</div>
                                    </div>
                                    <div className={css.detailRow}>
                                        <div className={css.detailCell}>Civil Sunrise/Sunset</div>
                                        <div className={css.detailCell}>{formatSolarEvent(solarData?.civilSunrise, solarData?.civilSunset)}</div>
                                    </div>
                                    <div className={css.detailRow}>
                                        <div className={css.detailCell}>Nautical Sunrise/Sunset</div>
                                        <div className={css.detailCell}>{formatSolarEvent(solarData?.nauticalSunrise, solarData?.nauticalSunset)}</div>
                                    </div>
                                    <div className={css.detailRow}>
                                        <div className={css.detailCell}>Astronomical Sunrise/Sunset</div>
                                        <div className={css.detailCell}>{formatSolarEvent(solarData?.astroSunrise, solarData?.astroSunset)}</div>
                                    </div>
                                    <div className={css.detailRow}>
                                        <div className={css.detailCell}>Day Length</div>
                                        <div className={css.detailCell}>{formatDayLength(solarData)}</div>
                                    </div>
                                    <div className={css.detailRow}>
                                        <div className={css.detailCell}>Solar Position</div>
                                        <div className={css.detailCell}>{formatSolarElevation(solarData)}</div>
                                    </div>
                                    <div className={css.detailRow}>
                                        <div className={css.detailCell}>Total rain last 24h</div>
                                        <div className={css.detailCell}>{suffixFormatter(extremesData?.imperial.lastDay.totalRain, 2, "\"")}</div>
                                    </div>
                                    <div className={css.detailRow}>
                                        <div className={css.detailCell}>Max temp last 24h</div>
                                        <div className={css.detailCell}>{suffixFormatter(extremesData?.imperial.lastDay.temperature.max, 1, "°F")} / {suffixFormatter(extremesData?.metric.lastDay.temperature.max, 1, "°C")}</div>
                                    </div>
                                    <div className={css.detailRow}>
                                        <div className={css.detailCell}>Min temp last 24h</div>
                                        <div className={css.detailCell}>{suffixFormatter(extremesData?.imperial.lastDay.temperature.min, 1, "°F")} / {suffixFormatter(extremesData?.metric.lastDay.temperature.min, 1, "°C")}</div>
                                    </div>
                                    <div className={css.detailRow}>
                                        <div className={css.detailCell}>Temperature trend</div>
                                        <div className={css.detailCell}>N/A</div>
                                    </div>
                                    <>
                                    {
                                    // Last Updated: N/A
                                    // Time Of Day:	day
                                    // Inside Temperature:	68.7° F (fan on)
                                    // Inside Humidity:	40%
                                    // Outside Humidity:	90%
                                    // Heat Index:	36.6° F - 2.6° C
                                    // Dewpoint:	33.9° F - 1.1° C
                                    // Barometric Pressure:	100.813 kPa
                                    // Irradiance:	74 W/m2
                                    // Wind Speed:	5 mph / 8.05 km/h - 203° SSW↙
                                    // Wind Gust:	7 mph / 11.3 km/h - 203° SSW↙
                                    // Rain Rate:	0 in/hr / 0 mm/hr
                                    // Official Sunrise/Sunset	7:26 am / 4:22 pm
                                    // Civil Sunrise/Sunset	6:52 am / 4:56 pm
                                    // Nautical Sunrise/Sunset	6:15 am / 5:33 pm
                                    // Astronomical Sunrise/Sunset	5:40 am / 6:08 pm
                                    // Day Length	8h 56m
                                    // Solar Elevation	20.137753290756514
                                    // Total rain last 24h	0.1" / 2.5mm
                                    // Max temp last 24h	39.2°F / 4°C
                                    // Min temp last 24h	34.3°F / 1.3°C
                                    // Temperature trend	↑
                                    }
                                    </>
                                </div>
                            </div>
                            <div className={css.detailContainerColumn}>
                                (Values are infrequently generated, there may be some lag)
                                {!extremesData
                                    ? <><br />No Data</>
                                    :
                                    <div className={css.detailTable}>
                                        <div className={css.sideTitle}>As of {extremeTime}...</div>
                                        <div className={css.sideTitle}>Last 15:</div>
                                       {/* <div className={css.detailRow}>
                                            <div className={css.detailCell}>Feels</div>
                                            <div className={css.detailCell}>Min</div>
                                            <div className={css.detailCell}>{extremesData?.imperial.lastFifteen.feelsLike.min}&deg;F</div>
                                            <div className={css.detailCell}>Avg</div>
                                            <div className={css.detailCell}>{extremesData?.imperial.lastFifteen.feelsLike.avg}&deg;F</div>
                                            <div className={css.detailCell}>Max</div>
                                            <div className={css.detailCell}>{extremesData?.imperial.lastFifteen.feelsLike.max}&deg;F</div>
                                        </div>*/}
                                        <div className={css.detailRow}>
                                            <div className={css.detailCell}>Air Temp</div>
                                            <div className={css.detailCell}>Min</div>
                                            <div className={css.detailCell}>{extremesData?.imperial.lastFifteen.temperature.min}&deg;F</div>
                                            <div className={css.detailCell}>Avg</div>
                                            <div className={css.detailCell}>{extremesData?.imperial.lastFifteen.temperature.avg}&deg;F</div>
                                            <div className={css.detailCell}>Max</div>
                                            <div className={css.detailCell}>{extremesData?.imperial.lastFifteen.temperature.max}&deg;F</div>
                                        </div>
                                        <div className={css.detailRow}>
                                            <div className={css.detailCell}>Wind Speed</div>
                                            <div className={css.detailCell}>Min</div>
                                            <div className={css.detailCell}>{extremesData?.imperial.lastFifteen.wind.min}mph</div>
                                            <div className={css.detailCell}>Avg</div>
                                            <div className={css.detailCell}>{extremesData?.imperial.lastFifteen.wind.avg}mph</div>
                                            <div className={css.detailCell}>Max</div>
                                            <div className={css.detailCell}>{extremesData?.imperial.lastFifteen.wind.max}mph</div>
                                        </div>
                                        <div className={css.detailRow}>
                                            <div className={css.detailCell}>Wind Gust</div>
                                            <div className={css.detailCell}></div>
                                            <div className={css.detailCell}></div>
                                            <div className={css.detailCell}></div>
                                            <div className={css.detailCell}></div>
                                            <div className={css.detailCell}>Max</div>
                                            <div className={css.detailCell}>{extremesData?.imperial.lastFifteen.maxGust}mph</div>
                                        </div>
                                        <div className={css.detailRow}>
                                            <div className={css.detailCell}>Rain</div>
                                            <div className={css.detailCell}></div>
                                            <div className={css.detailCell}></div>
                                            <div className={css.detailCell}></div>
                                            <div className={css.detailCell}></div>
                                            <div className={css.detailCell}>Total</div>
                                            <div className={css.detailCell}>{extremesData?.imperial.lastFifteen.totalRain} inches</div>
                                        </div>

                                        <div className={css.sideTitle}>Last Hour:</div>
                                        {/*<div className={css.detailRow}>
                                            <div className={css.detailCell}>Feels</div>
                                            <div className={css.detailCell}>Min</div>
                                            <div className={css.detailCell}>{extremesData?.imperial.lastHour.feelsLike.min}&deg;F</div>
                                            <div className={css.detailCell}>Avg</div>
                                            <div className={css.detailCell}>{extremesData?.imperial.lastHour.feelsLike.avg}&deg;F</div>
                                            <div className={css.detailCell}>Max</div>
                                            <div className={css.detailCell}>{extremesData?.imperial.lastHour.feelsLike.max}&deg;F</div>
                                        </div>*/}
                                        <div className={css.detailRow}>
                                            <div className={css.detailCell}>Air Temp</div>
                                            <div className={css.detailCell}>Min</div>
                                            <div className={css.detailCell}>{extremesData?.imperial.lastHour.temperature.min}&deg;F</div>
                                            <div className={css.detailCell}>Avg</div>
                                            <div className={css.detailCell}>{extremesData?.imperial.lastHour.temperature.avg}&deg;F</div>
                                            <div className={css.detailCell}>Max</div>
                                            <div className={css.detailCell}>{extremesData?.imperial.lastHour.temperature.max}&deg;F</div>
                                        </div>
                                        <div className={css.detailRow}>
                                            <div className={css.detailCell}>Wind Speed</div>
                                            <div className={css.detailCell}>Min</div>
                                            <div className={css.detailCell}>{extremesData?.imperial.lastHour.wind.min}mph</div>
                                            <div className={css.detailCell}>Avg</div>
                                            <div className={css.detailCell}>{extremesData?.imperial.lastHour.wind.avg}mph</div>
                                            <div className={css.detailCell}>Max</div>
                                            <div className={css.detailCell}>{extremesData?.imperial.lastHour.wind.max}mph</div>
                                        </div>
                                        <div className={css.detailRow}>
                                            <div className={css.detailCell}>Wind Gust</div>
                                            <div className={css.detailCell}></div>
                                            <div className={css.detailCell}></div>
                                            <div className={css.detailCell}></div>
                                            <div className={css.detailCell}></div>
                                            <div className={css.detailCell}>Max</div>
                                            <div className={css.detailCell}>{extremesData?.imperial.lastHour.maxGust}mph</div>
                                        </div>
                                        <div className={css.detailRow}>
                                            <div className={css.detailCell}>Rain</div>
                                            <div className={css.detailCell}></div>
                                            <div className={css.detailCell}></div>
                                            <div className={css.detailCell}></div>
                                            <div className={css.detailCell}></div>
                                            <div className={css.detailCell}>Total</div>
                                            <div className={css.detailCell}>{extremesData?.imperial.lastHour.totalRain} inches</div>
                                        </div>

                                        <div className={css.sideTitle}>Last Day:</div>
                                        {/*<div className={css.detailRow}>
                                            <div className={css.detailCell}>Feels</div>
                                            <div className={css.detailCell}>Min</div>
                                            <div className={css.detailCell}>{extremesData?.imperial.lastDay.feelsLike.min}&deg;F</div>
                                            <div className={css.detailCell}>Avg</div>
                                            <div className={css.detailCell}>{extremesData?.imperial.lastDay.feelsLike.avg}&deg;F</div>
                                            <div className={css.detailCell}>Max</div>
                                            <div className={css.detailCell}>{extremesData?.imperial.lastDay.feelsLike.max}&deg;F</div>
                                        </div>*/}
                                        <div className={css.detailRow}>
                                            <div className={css.detailCell}>Air Temp</div>
                                            <div className={css.detailCell}>Min</div>
                                            <div className={css.detailCell}>{extremesData?.imperial.lastDay.temperature.min}&deg;F</div>
                                            <div className={css.detailCell}>Avg</div>
                                            <div className={css.detailCell}>{extremesData?.imperial.lastDay.temperature.avg}&deg;F</div>
                                            <div className={css.detailCell}>Max</div>
                                            <div className={css.detailCell}>{extremesData?.imperial.lastDay.temperature.max}&deg;F</div>
                                        </div>
                                        <div className={css.detailRow}>
                                            <div className={css.detailCell}>Wind Speed</div>
                                            <div className={css.detailCell}>Min</div>
                                            <div className={css.detailCell}>{extremesData?.imperial.lastDay.wind.min}mph</div>
                                            <div className={css.detailCell}>Avg</div>
                                            <div className={css.detailCell}>{extremesData?.imperial.lastDay.wind.avg}mph</div>
                                            <div className={css.detailCell}>Max</div>
                                            <div className={css.detailCell}>{extremesData?.imperial.lastDay.wind.max}mph</div>
                                        </div>
                                        <div className={css.detailRow}>
                                            <div className={css.detailCell}>Wind Gust</div>
                                            <div className={css.detailCell}></div>
                                            <div className={css.detailCell}></div>
                                            <div className={css.detailCell}></div>
                                            <div className={css.detailCell}></div>
                                            <div className={css.detailCell}>Max</div>
                                            <div className={css.detailCell}>{extremesData?.imperial.lastDay.maxGust}mph</div>
                                        </div>
                                        <div className={css.detailRow}>
                                            <div className={css.detailCell}>Rain</div>
                                            <div className={css.detailCell}></div>
                                            <div className={css.detailCell}></div>
                                            <div className={css.detailCell}></div>
                                            <div className={css.detailCell}></div>
                                            <div className={css.detailCell}>Total</div>
                                            <div className={css.detailCell}>{extremesData?.imperial.lastDay.totalRain} inches</div>
                                        </div>
                                        
                                        <div className={css.sideTitle}>Last Week:</div>
                                        {/*<div className={css.detailRow}>
                                            <div className={css.detailCell}>Feels</div>
                                            <div className={css.detailCell}>Min</div>
                                            <div className={css.detailCell}>{extremesData?.imperial.lastWeek.feelsLike.min}&deg;F</div>
                                            <div className={css.detailCell}>Avg</div>
                                            <div className={css.detailCell}>{extremesData?.imperial.lastWeek.feelsLike.avg}&deg;F</div>
                                            <div className={css.detailCell}>Max</div>
                                            <div className={css.detailCell}>{extremesData?.imperial.lastWeek.feelsLike.max}&deg;F</div>
                                        </div>*/}
                                        <div className={css.detailRow}>
                                            <div className={css.detailCell}>Air Temp</div>
                                            <div className={css.detailCell}>Min</div>
                                            <div className={css.detailCell}>{extremesData?.imperial.lastWeek.temperature.min}&deg;F</div>
                                            <div className={css.detailCell}>Avg</div>
                                            <div className={css.detailCell}>{extremesData?.imperial.lastWeek.temperature.avg}&deg;F</div>
                                            <div className={css.detailCell}>Max</div>
                                            <div className={css.detailCell}>{extremesData?.imperial.lastWeek.temperature.max}&deg;F</div>
                                        </div>
                                        <div className={css.detailRow}>
                                            <div className={css.detailCell}>Wind Speed</div>
                                            <div className={css.detailCell}>Min</div>
                                            <div className={css.detailCell}>{extremesData?.imperial.lastWeek.wind.min}mph</div>
                                            <div className={css.detailCell}>Avg</div>
                                            <div className={css.detailCell}>{extremesData?.imperial.lastWeek.wind.avg}mph</div>
                                            <div className={css.detailCell}>Max</div>
                                            <div className={css.detailCell}>{extremesData?.imperial.lastWeek.wind.max}mph</div>
                                        </div>
                                        <div className={css.detailRow}>
                                            <div className={css.detailCell}>Wind Gust</div>
                                            <div className={css.detailCell}></div>
                                            <div className={css.detailCell}></div>
                                            <div className={css.detailCell}></div>
                                            <div className={css.detailCell}></div>
                                            <div className={css.detailCell}>Max</div>
                                            <div className={css.detailCell}>{extremesData?.imperial.lastWeek.maxGust}mph</div>
                                        </div>
                                        <div className={css.detailRow}>
                                            <div className={css.detailCell}>Rain</div>
                                            <div className={css.detailCell}></div>
                                            <div className={css.detailCell}></div>
                                            <div className={css.detailCell}></div>
                                            <div className={css.detailCell}></div>
                                            <div className={css.detailCell}>Total</div>
                                            <div className={css.detailCell}>{extremesData?.imperial.lastWeek.totalRain} inches</div>
                                        </div>


                                        <div className={css.sideTitle}>Last Month:</div>
                                        {/*<div className={css.detailRow}>
                                            <div className={css.detailCell}>Feels</div>
                                            <div className={css.detailCell}>Min</div>
                                            <div className={css.detailCell}>{extremesData?.imperial.lastMonth.feelsLike.min}&deg;F</div>
                                            <div className={css.detailCell}>Avg</div>
                                            <div className={css.detailCell}>{extremesData?.imperial.lastMonth.feelsLike.avg}&deg;F</div>
                                            <div className={css.detailCell}>Max</div>
                                            <div className={css.detailCell}>{extremesData?.imperial.lastMonth.feelsLike.max}&deg;F</div>
                                        </div>*/}
                                        <div className={css.detailRow}>
                                            <div className={css.detailCell}>Air Temp</div>
                                            <div className={css.detailCell}>Min</div>
                                            <div className={css.detailCell}>{extremesData?.imperial.lastMonth.temperature.min}&deg;F</div>
                                            <div className={css.detailCell}>Avg</div>
                                            <div className={css.detailCell}>{extremesData?.imperial.lastMonth.temperature.avg}&deg;F</div>
                                            <div className={css.detailCell}>Max</div>
                                            <div className={css.detailCell}>{extremesData?.imperial.lastMonth.temperature.max}&deg;F</div>
                                        </div>
                                        <div className={css.detailRow}>
                                            <div className={css.detailCell}>Wind Speed</div>
                                            <div className={css.detailCell}>Min</div>
                                            <div className={css.detailCell}>{extremesData?.imperial.lastMonth.wind.min}mph</div>
                                            <div className={css.detailCell}>Avg</div>
                                            <div className={css.detailCell}>{extremesData?.imperial.lastMonth.wind.avg}mph</div>
                                            <div className={css.detailCell}>Max</div>
                                            <div className={css.detailCell}>{extremesData?.imperial.lastMonth.wind.max}mph</div>
                                        </div>
                                        <div className={css.detailRow}>
                                            <div className={css.detailCell}>Wind Gust</div>
                                            <div className={css.detailCell}></div>
                                            <div className={css.detailCell}></div>
                                            <div className={css.detailCell}></div>
                                            <div className={css.detailCell}></div>
                                            <div className={css.detailCell}>Max</div>
                                            <div className={css.detailCell}>{extremesData?.imperial.lastMonth.maxGust}mph</div>
                                        </div>
                                        <div className={css.detailRow}>
                                            <div className={css.detailCell}>Rain</div>
                                            <div className={css.detailCell}></div>
                                            <div className={css.detailCell}></div>
                                            <div className={css.detailCell}></div>
                                            <div className={css.detailCell}></div>
                                            <div className={css.detailCell}>Total</div>
                                            <div className={css.detailCell}>{extremesData?.imperial.lastMonth.totalRain.toFixed(2)} inches</div>
                                        </div>
                                    </div>
                                }
                                    
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        )
    }
import { rem, transform } from "csx"
import { stylesheet } from "typestyle"
import { MPHtoKPH } from "../views/utils"

interface WindCompassProps {
    windSpeed?: number
    windGust?: number
    windDirection?: number
    windGustDirection?: number
    showMetric?: boolean
}

const css = stylesheet({
    sideStat: {
        fontSize: '24pt'
    },
    speedSize: {
        fontSize: '16pt'
    },
    bar: {
        transition: "transform 250ms"
    }
})

const
    extrapolate = (x1: number, x2: number, y1: number, y2: number, x: number): number => {
        return y1 * (1 - ((x - x1)/(x2 - x1))) + y2 * ((x - x1) / (x2 - x1))
    },
    interpolate = (x1: number, x2: number, y1: number, y2: number, x: number): number => {
        if (x >= x2) {
            return y2
        } else if (x <= x1) {
            return y1
        }
        return extrapolate(x1, x2, y1, y2, x)
    }

export const WindCompass = 
    ({windSpeed, windGust, windDirection, windGustDirection, showMetric}: WindCompassProps) => {
        const roseTicks = []
        for (let i = 0; i < 360; i += 22.5) {
            roseTicks.push(<line
                key={"tick_"+i}
                x1="50" y1="0" 
                x2="50" y2 = "10"
                stroke="#000"
                transform={"rotate("+i+" 50 50)"}
                />)
        }

        const
            windSpeedForText = showMetric ? MPHtoKPH(windSpeed) : windSpeed,
            windGustForText = showMetric ? MPHtoKPH(windGust) : windGust,
            suffix = showMetric ? "km/h" : "mph",
            precision = showMetric ? 1 : 0
        

        const
            createWindRect = (windSpeed: (number | null | undefined), y: number) => {
                if (!windSpeed || windSpeed === 0) {
                    return <></>
                }
                const
                    width = interpolate(0, 30, 0, 100, windSpeed),
                    color = width > 99 ? "#00F" :
                            width > 95 ? "#F00" :
                            width > 85 ? "#E32" :
                            width > 75 ? "#D62" :
                            width > 65 ? "#D92" :
                            width > 55 ? "#DA2" :
                            width > 45 ? "#DD2" :
                            width > 45 ? "#AD2" :
                            width > 35 ? "#9D2" :
                            width > 25 ? "#6D2" :
                            width > 15 ? "#3D2" :
                            width > 5 ? "#2F2" :
                            "#0F0"



                return (<rect
                        className={css.bar}
                        x = "0"
                        y = {y}
                        width = {width}
                        height = "5"
                        fill = {color}
                    />)
            },
            windSpeedText = (speed?: number, gust?: number) => {
                if (windSpeedForText === null || windSpeedForText === undefined || windGustForText === null || windGustForText === undefined) {
                    return <div className={css.sideStat}>N/A</div>
                } else if (windGustForText < 0.4) {
                    return <div><div className={css.speedSize}>Calm</div><div className={css.speedSize}>&nbsp;</div></div>
                }
                return <div>
                    <div className={css.speedSize}>
                        Wind: {windSpeedForText.toFixed(precision) + suffix}
                    </div>
                    <div className={css.speedSize}>
                        Gust: {windGustForText.toFixed(precision) + suffix}
                    </div>
                </div>
            }


        return (
            <><svg width={rem(8)} height={rem(10)} viewBox="0 0 100 120">
                <circle cx={50} cy={50} r={50} fill="#444" />
                {roseTicks}
                {windDirection !== undefined && windSpeed !== undefined && windSpeed > 0 ?
                    <polygon 
                        points="50,5 71,90 50,65 29,90" 
                        transform={"rotate("+windDirection+" 50 50)"}
                        fill="#e44"/> : null}
                {windGustDirection !== undefined && windGust !== undefined && windGust > 0 ? 
                    <polygon 
                        points="50,5 52,10 52,90 50,88 48,90 48,10" 
                        transform={"rotate("+windGustDirection+" 50 50)"}
                        fill="#4FF"/> : null }
                <circle cx={50} cy={50} r={2} fill="#111" />

                <rect x="0" y="105" width="100" height="5" fill="#555" />
                <rect x="0" y="115" width="100" height="5" fill="#555" />
                {
                    createWindRect(windSpeed, 105)
                }
                {
                    createWindRect(windGust, 115)
                }

            </svg>
            {windSpeedText(windSpeed, windGust)}
            </>
        )
    }
import React from 'react';
import { media, stylesheet, extend } from 'typestyle';
import { WebsocketConnection } from '../comm/sock';
import { WeewxData, WeatherStationData, WeewxProtobufData } from '../comm/packets';
import { DesktopView } from '../views/DesktopView'
import { MobileViewRoot } from '../views/MobileView'
import { WeatherAPI } from '../comm/wxapi';
import { Alert, GetExtremesDataOut, GetLoopinDataOut, GetSolarDataOut, WebsocketMessage } from '../wxsite';

const responsiveBreak = 1100

const css = stylesheet({
    root: {
        position: "absolute",
        left: 0, top: 0, right: 0, bottom: 0,
        padding: 0, margin: 0
    },
    desktopContainer: {
        padding: 0, margin: 0,
        ...extend(
            media(
                {minWidth: 0, maxWidth: responsiveBreak},
                {visibility: "hidden"}
            ),
            media(
                {minWidth: responsiveBreak},
                {}
            )
        )
    },
    mobileContainer: {
        padding: 0, margin: 0,
        ...extend(
            media(
                {minWidth: 0, maxWidth: responsiveBreak},
                {}
            ),
            media(
                {minWidth: responsiveBreak},
                {visibility: "hidden"}
            )
        )
    },

  })

interface RootViewProps {
    sock: WebsocketConnection
    wxapi: WeatherAPI
}

export const objToStrMap = (obj: any): Map<any, any> => {
    let strMap = new Map();
    for (let k of Object.keys(obj)) {
      strMap.set(k, obj[k]);
    }
    return strMap;
  }

const extractNumber = (map: Map<unknown, unknown>, key: string): number => {
    const value = map.get(key)

    if (value === undefined) {
        window.console.error("weewx sample missing "+key)
    }

    try {
        return value as number;
    } catch(e) {
        window.console.error(e, "weewx sample missing "+key)
        return -9999
    }    
}

const convertWeewxRawData = (data: any): WeatherStationData => {
    
    const dataMap = objToStrMap(data)

    const v = {
        SampleTime: new Date(extractNumber(dataMap, "dateTime")),
        Barometer: extractNumber(dataMap, "barometer"),
        Pressure: extractNumber(dataMap, "pressure"),
        Altimeter: extractNumber(dataMap, "altimeter"),
        OutTemp: extractNumber(dataMap, "outTemp"),
        OutHumidity: extractNumber(dataMap, "outHumidity"),
        WindSpeed: extractNumber(dataMap, "windSpeed"),
        WindDirection: extractNumber(dataMap, "windDir"),
        WindGust: extractNumber(dataMap, "windGust"),
        WindGustDirection: extractNumber(dataMap, "windGustDir"),
        RainRate: extractNumber(dataMap, "rainRate"),
        Rain: extractNumber(dataMap, "rain"),
        DewPoint: extractNumber(dataMap, "dewpoint"),
        WindChill: extractNumber(dataMap, "windchill"),
        HeatIndex: extractNumber(dataMap, "heatindex"),
        ET: extractNumber(dataMap, "ET"),
        Radiation: extractNumber(dataMap, "radiation"),
        UV: extractNumber(dataMap, "UV"),
        Humidex: extractNumber(dataMap, "humidex"),
        FeelsLike: extractNumber(dataMap, "feels_like"),
    }

    return v
}

const parseWeewxData = (data: string): (WeewxData | undefined) => {
    const rootMap = objToStrMap(JSON.parse(data))

    const dataRoot = objToStrMap(rootMap.get("data") as any)

    const 
        imperial = convertWeewxRawData(dataRoot.get("raw")),
        metric = convertWeewxRawData(dataRoot.get("metric"))
    
    return {
        imperial: imperial,
        metric: metric,
    }
    return undefined
}

interface MessageReceiver {
    type: string
}

export interface ExtremesData {
    metric: GetExtremesDataOut
    imperial: GetExtremesDataOut
}

export const RootView = 
    ({sock, wxapi}: RootViewProps) => {
        const
            [weewxData, setWeewxData] = React.useState(undefined as (WeewxProtobufData | undefined)),
            [extremesData, setExtremesData] = React.useState(undefined as (ExtremesData | undefined)),
            [solarData, setSolarData] = React.useState(undefined as (GetSolarDataOut | undefined)),
            [loopinData, setLoopinData] = React.useState(undefined as (GetLoopinDataOut | undefined)),
            [activeAlerts, setActiveAlerts] = React.useState<Alert[] | undefined>(undefined),
            [alertActive, setAlertActive] = React.useState<boolean | undefined>(undefined)
        
        const
            updateAlerts = () => {
                wxapi.getActiveAlerts().then((alerts) => {
                    setActiveAlerts(alerts.sort((a,b) => {
                        return a.effective > b.effective ? -1 : 1
                    }))
                }).catch((e) => {
                    setActiveAlerts(undefined)
                    console.log("Alert fetch failed: "+e)
                })
            }

        React.useEffect(() => {
            let interval = null;
            if (alertActive !== true) {
                return
            }

            updateAlerts()
            const timer = setInterval(() => {
                updateAlerts()
            }, 15000)
            return () => {
                clearInterval(timer)
            }
        }, [alertActive]);

        

        React.useEffect(() => {
            if (alertActive !== undefined) {
                return
            }

            wxapi.getAlertingEnabled().then((en) => {
                setAlertActive(en)
                console.log("Alerting enabled: "+en)
            }).catch((e) => {
                console.log("Alert fetch failed: "+e)
                setAlertActive(false)
            })

        }, [alertActive])

        React.useEffect(() => {
            const listener = sock.addListener((data: string) => {
                let jdata: WebsocketMessage = JSON.parse(data)

                if (jdata.messageType === "new-data") {
                    if (jdata.messageArgument === "weewx") {
                        wxapi.getWeatherData("f").then((imperialData) => {
                            wxapi.getWeatherData("c").then((metricData) => {
                                setWeewxData({
                                    imperial: imperialData,
                                    metric: metricData,
                                    timestamp: new Date(metricData.epoch * 1000),
                                })
                            }).catch((e) => {console.error(e); setWeewxData(undefined)})
                        }).catch((e) => {console.error(e); setWeewxData(undefined)})
                    } else if (jdata.messageArgument === "extremes") {
                        wxapi.getExtremesData("f").then((imperialExtremes) => {
                            wxapi.getExtremesData("c").then((metricExtremes) => {
                                setExtremesData({
                                    imperial: imperialExtremes,
                                    metric: metricExtremes,
                                })
                            }).catch((e) => {console.error(e); setExtremesData(undefined)})
                        }).catch((e) => {console.error(e); setExtremesData(undefined)})
                    } else if (jdata.messageArgument === "solar") {
                        wxapi.getSolarData().then((solarData) => {
                            setSolarData(solarData)
                        }).catch((e) => {console.error(e); setSolarData(undefined)})
                    } else if (jdata.messageArgument === "weewx_loopin") {
                        wxapi.getLoopinData().then((loopinData) => {
                            setLoopinData(loopinData)
                        }).catch((e) => {console.error(e); setLoopinData(undefined)})
                    } else {
                        window.console.log("unsupported data type "+jdata.messageArgument)
                    }
                } else {
                    window.console.log("unsupported message" + jdata.messageType)
                }
            })

            return () => {
                sock.removeListener(listener)
            }

        }, [sock])
        
        return (
            <>
                <div className={css.desktopContainer}>
                    <DesktopView 
                        weewxData={weewxData}
                        extremesData={extremesData}
                        solarData={solarData}
                        loopinData={loopinData}
                        alerts={alertActive ? activeAlerts : undefined}
                    />
                </div>
                <div className={css.mobileContainer}>
                    <MobileViewRoot
                        weewxData={weewxData}
                        extremesData={extremesData}
                        solarData={solarData}
                        loopinData={loopinData}
                        alerts={alertActive ? activeAlerts : undefined}
                    />
                </div>
            </>
        )
    }

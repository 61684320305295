import { GetTokenIn, WebsocketMessage } from "../wxsite";

type websocketCallback = (m: string) => void

const isDev = process.env.NODE_ENV === 'development';

export class WebsocketConnection {
    socket: WebSocket
    listeners: Set<websocketCallback>
    tokenUpdater: (newToken: string, ttl: number) => void

    constructor(tokenUpdater: (newToken: string, ttl: number) => void) {
        this.tokenUpdater = tokenUpdater
        const protocol = window.location.protocol === "https:" ? "wss:" : "ws:"
        this.socket = isDev ? 
            new WebSocket(protocol + "//localhost:9773/ws") :
            new WebSocket(protocol + "//" + window.location.host + "/ws")
        this.listeners = new Set<websocketCallback>()
        this.socket.onmessage = (event) => {            
            const jsonData = JSON.parse(event.data)

            const wsMessage: WebsocketMessage = jsonData
            if (wsMessage.messageType == "new-token") {
                tokenUpdater(wsMessage.tokenData.newToken, wsMessage.tokenData.ttl)
            } else {
                this.listeners.forEach((l) => {
                    l(event.data)
                })
            }
        }
        this.socket.onclose = () => {
            window.console.log("Socket gone, reloading")
            window.location.reload()
        }

    }

    close() {
        window.console.log("closing connection")
        this.socket.close()
    }

    addListener(f: websocketCallback): websocketCallback {
        this.listeners.add(f)
        return f
    }

    removeListener(f: websocketCallback) {
        this.listeners.delete(f)
    }
}
import React from 'react';
import { style, media, stylesheet, extend } from 'typestyle';
import { border, em, px, background, percent, rgb, padding, rem, rgba, linearGradient, rad } from 'csx';
import { SolarData, WeewxProtobufData } from '../comm/packets';
import { objToStrMap, ExtremesData } from './RootView';
import { WindCompass } from '../components/winddirection'
import { Alert, GetExtremesDataOut, GetLoopinDataOut, GetSolarDataOut } from '../wxsite';
import { FtoC, MPHtoKPH, InchestoMM, getBackground, formatSolarEvent, formatDayLength, formatSolarElevation } from "./utils"
import { DefaultButton, Dialog, Spinner, SpinnerSize, Toggle } from '@fluentui/react';
import { CombinedViewProps } from './Combined';

const css = stylesheet({
    root: {
        margin: 0, padding: 0,
        position: "fixed", left: 0, right: 0, top: 0, bottom: 0,
        fontFamily: "palatino linotype, seirf",
        boxSizing: "border-box",
        display: "flex",
        overflow: "scroll",
        flexDirection: "column",
    },
    boxHolder: {
        width: percent(100),
        boxSizing: "border-box",
        paddingRight: em(1),
        paddingLeft: em(1),
        paddingBottom: em(1),
    },
    boxTitle: {
        fontStyle: "italic",
        fontWeight: 600,
        fontSize: "18pt",
    },
    box: {
        backgroundColor: rgba(255, 255, 255, 0.3).toString(),
        padding: em(0.5),
        fontSize: "13pt",
    },
    flexBoxHolder: {
        display: "flex",
        gap: em(1),
    },
    flexBoxItem: {
        maxWidth: percent(45),
    },
    
    header: {
        fontSize: "18pt",
        textAlign: "center",
        marginBottom: em(1),
        width: percent(100),
        padding: em(0.5),
        fontWeight: 600,
        backgroundColor: rgba(255, 255, 255, 0.3).toString(),
        boxSizing: "border-box"
    },
    detailTable: {
        minWidth: em(10),
        display: "table"
    },
    detailRow: {
        display: "table-row",
        height: px(16),
    },
    detailCell: {
        display: "table-cell",
        
        height: px(20),
    },
    detailCellData: {
        display: "table-cell",
        paddingLeft: rem(0.5),
        height: px(20),
    },
    detailCellContents: {
        height: px(30),
        position: "absolute",
    },
    infoBox: {
        paddingLeft : rem(0),
        fontSize: "16pt",
        textAlign: "right",
    },
    alertContainer: {
        fontSize: '12pt',
    },
    alertEntry: {
        border: border({color: 'white', width: '1px', style: 'solid'}),
        background: 'linear-gradient( to top, #911, #500)',
        margin: rem(0.25),
        color: 'white',
        padding: rem(0.1),
    },
    preformatedAlertText: {
        whiteSpace: "pre-line",
        fontFamily: "monospace",
        marginBottom: rem(1),
    }

    
})

interface MobileViewRootProps {
    weewxData?: WeewxProtobufData
    extremesData?: ExtremesData
    solarData?: GetSolarDataOut
    loopinData?: GetLoopinDataOut
    alerts?: Alert[]
}


export const MobileViewRoot = 
    ({weewxData, solarData, extremesData, loopinData, alerts}: MobileViewRootProps) => {
        const
            [isMetric, setIsMetric] = React.useState<boolean>(false),
            toggleMetric = React.useMemo(() => {
                return () => {
                    setIsMetric(!isMetric)
                }                
            }, [isMetric]),
            combined: CombinedViewProps = {
                isMetric: isMetric,
                setMetric: setIsMetric,
                toggleMetric: toggleMetric,
            }

        if (loopinData !== undefined) {
            if (isMetric) {
                combined.currentTemperature = FtoC(loopinData.outtemp)
                combined.currentHumidity = loopinData.outhumidity
                combined.currentWindSpeed = MPHtoKPH(loopinData.windspeed)
                combined.currentWindGust = MPHtoKPH(loopinData.windgust)
                combined.currentWindDirection = loopinData.windDir
                combined.currentWindGustDirection = loopinData.windgustdir
                combined.currentRainRate = InchestoMM(loopinData.rainrate)
            } else {
                combined.currentTemperature = loopinData.outtemp
                combined.currentHumidity = loopinData.outhumidity
                combined.currentWindSpeed = loopinData.windspeed
                combined.currentWindGust = loopinData.windgust
                combined.currentWindDirection = loopinData.windDir
                combined.currentWindGustDirection = loopinData.windgustdir
                combined.currentRainRate = loopinData.rainrate
            }
            combined.currentSolar = loopinData.radiation
        } else if (weewxData !== undefined) {
            if (isMetric) {
                combined.currentTemperature = weewxData.metric.outsideTemp
                combined.currentHumidity = weewxData.metric.outsideHumidity
                combined.currentWindSpeed = weewxData.metric.windSpeed
                combined.currentWindGust = weewxData.metric.windGust
                combined.currentWindDirection = weewxData.metric.windDir
                combined.currentWindGustDirection = weewxData.metric.windGustDir
                combined.currentRainRate = weewxData.metric.rainRate
            } else {
                combined.currentTemperature = weewxData.imperial.outsideTemp
                combined.currentHumidity = weewxData.imperial.outsideHumidity
                combined.currentWindSpeed = weewxData.imperial.windSpeed
                combined.currentWindGust = weewxData.imperial.windGust
                combined.currentWindDirection = weewxData.imperial.windDir
                combined.currentWindGustDirection = weewxData.imperial.windGustDir
                combined.currentRainRate = weewxData.imperial.rainRate
            }
            combined.currentSolar = weewxData.metric.rad
        }

        if (weewxData !== undefined) {
            if (isMetric) {
                combined.currentFeels = weewxData.metric.feelsLike
            } else {
                combined.currentFeels = weewxData.imperial.feelsLike
            }
        }

        if (alerts === undefined) {
            console.log("MobileView root undefined alerts")
        }

        combined.weewxData = weewxData
        combined.solarData = solarData
        combined.extremes = extremesData
        combined.loopinData = loopinData

        return <MobileView
            weatherData={combined}
            alerts={alerts}
            />
    }



interface MobileViewProps {
    weatherData: CombinedViewProps
    alerts?: Alert[]
}

const toTitleCase = (str: string | undefined) => {
    if (str === undefined) {
        return ""
    }
    return str.replace(
      /\w\S*/g,
      function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      }
    );
}


const MobileView = 
    ({weatherData, alerts}: MobileViewProps) => {

        const
            [alertDialogVisible, setAlertDialogVisible] = React.useState<boolean | undefined>(undefined),
            [dialogAlert, setDialogAlert] = React.useState<Alert | undefined>(undefined),
            bg: string = (weatherData.weewxData !== undefined) ?
            (
                getBackground(weatherData.weewxData)
            ) : "linear-gradient( to top, #DDD, #111)",
            formatAlerts = React.useCallback(() => {
                if (alerts === undefined) {
                    console.log("Alerts undefined")
                    return undefined
                }
                if (alerts.length === 0) {
                    console.log("Zero alerts")
                    return undefined
                }
                return (
                    <div className={css.alertContainer}>
                        {alerts.map((a) => {
                            return (
                                <div 
                                    key={a.systemid}
                                    className={css.alertEntry}
                                    onClick={() => {
                                        setDialogAlert(a)
                                    }}>
                                    {toTitleCase(a.title)}
                                </div>
                            )
                        })}
                    </div>
                    
                )
            }, [alerts])

            React.useEffect(() => {
                if (dialogAlert === undefined) {
                    setAlertDialogVisible(false)
                    return
                }
                setAlertDialogVisible(true)
            }, [dialogAlert])
        
        if (weatherData.currentTemperature === undefined) {
            return (
                <div className={css.root + " " + style({background: bg})}>
                    <div className={css.header}>
                        Montague Township
                        {formatAlerts()}
                    </div>
                    <div className={css.boxHolder}>
                        <div className={css.box}>
                            <Spinner size={SpinnerSize.large}/>
                            <div style={{fontSize: "18pt"}}>NO DATA</div>
                        </div>
                    </div>
                </div>
            )
        }

        const 
            formatTemp = (value: (number|undefined), metric: boolean, convert: boolean) => {
                if (metric && convert) {
                    value = FtoC(value)
                }
                if (value === undefined) {
                    if (metric) {
                        return <>N/A&deg;C</>
                    } else {
                        return <>N/A&deg;F</>
                    }
                }
                if (metric) {
                    return <>{value.toFixed(1)}&deg;C</>
                } else {
                    return <>{value.toFixed(1)}&deg;F</>
                }
            },
            formatWind = (value: (number|undefined), metric: boolean, convert: boolean) => {
                if (metric && convert) {
                    value = MPHtoKPH(value)
                }
                if (value === undefined) {
                    if (metric) {
                        return <>N/A km/h</>
                    } else {
                        return <>N/A mph</>
                    }
                }
                if (metric) {
                    return <>{value.toFixed(1)} km/h</>
                } else {
                    return <>{value.toFixed(0)} mph</>
                }
            },

            formatRainRate = (value: (number|undefined), metric: boolean, convert: boolean) => {
                if (value === undefined) {
                    if (metric) {
                        return <>N/A mm/h</>
                    } else {
                        return <>N/A in/h</>
                    }
                }
                if (metric && convert) {
                    value = value * 25.4
                }
                if (metric) {
                    return <>{value.toFixed(1)} mm/h</>
                } else {
                    return <>{value.toFixed(2)} in/h</>
                }
            },
            formatRain = (value: (number|undefined), metric: boolean, convert: boolean) => {
                if (value === undefined) {
                    if (metric) {
                        return <>N/A mm</>
                    } else {
                        return <>N/A "</>
                    }
                }
                if (metric && convert) {
                    value = value * 25.4
                }
                if (metric) {
                    return <>{value.toFixed(1)} mm</>
                } else {
                    return <>{value.toFixed(2)}"</>
                }
            },
            extremes = weatherData.extremes?.imperial !== undefined ? weatherData.extremes.imperial : undefined
        
        
            console.log("Dialog visible: "+alertDialogVisible)
        return (
            <div className={css.root + " " + style({background: bg})}>
                <div className={css.header}>
                    Montague Township
                    {formatAlerts()}
                    <Dialog
                            hidden={!alertDialogVisible}
                            dialogContentProps={{
                                showCloseButton: true,
                                title: toTitleCase(dialogAlert?.title),
                                
                            }}
                            onDismiss={() => setAlertDialogVisible(false)}
                            modalProps={{
                                onDismissed: () => {
                                    setDialogAlert(undefined)
                                }
                            }}
                            minWidth="80%"
                        >
                            {
                                dialogAlert && dialogAlert.effective > 0 &&
                                <><b>Effective: </b> {new Date(dialogAlert.effective*1000).toString()}<br /></>
                            }
                            {
                                dialogAlert && dialogAlert.expiry > 0 &&
                                <><b>Expires: </b> {new Date(dialogAlert.expiry*1000).toString()}</>
                            }
                            <div>
                                
                            </div>
                            <div className={css.preformatedAlertText}>
                                {dialogAlert?.description}<br />
                                {dialogAlert?.instruction}
                            </div>
                            <DefaultButton
                                onClick={() => setAlertDialogVisible(false)}
                            >Close</DefaultButton>
                        </Dialog>
                </div>
                <div className={css.boxHolder}>
                    <div className={css.box}>
                        <Toggle
                            onChange={(e, checked) => {
                                weatherData.setMetric(checked || false)
                            }}
                            onText='Metric'
                            offText='US'
                        />
                        <div className={css.flexBoxHolder}>
                            <div className={css.flexBoxItem}>
                                {
                                    weatherData.loopinData ? 
                                    <WindCompass windSpeed={weatherData.loopinData.windspeed} windGust={weatherData.loopinData.windgust} windDirection={weatherData.loopinData.windDir} windGustDirection={weatherData.loopinData.windgustdir} showMetric={weatherData.isMetric} />
                                    : <WindCompass windSpeed={weatherData.weewxData?.imperial.windSpeed} windGust={weatherData.weewxData?.imperial.windGust} windDirection={weatherData.weewxData?.imperial.windDir} windGustDirection={weatherData.weewxData?.imperial.windGustDir} showMetric={weatherData.isMetric} />
                                }
                            </div>
                            <div className={css.flexBoxItem}>
                                <div className={css.detailTable}>
                                    <div className={css.detailRow}>
                                        <div className={css.detailCell}>
                                            Air Temp
                                        </div>
                                        <div className={css.detailCell+ " " + css.infoBox}>
                                            {formatTemp(weatherData.currentTemperature, weatherData.isMetric, false)}
                                        </div>
                                        
                                    </div>
                                    <div className={css.detailRow}>
                                        <div className={css.detailCell}>
                                            Feels Like
                                        </div>
                                        <div className={css.detailCell + " " + css.infoBox}>
                                            {formatTemp(weatherData.currentFeels, weatherData.isMetric, false)}
                                        </div>
                                    </div>
                                    <div className={css.detailRow}>
                                        <div className={css.detailCell}>
                                            Humidity
                                        </div>
                                        <div className={css.detailCell + " " + css.infoBox}>
                                            {(weatherData.currentHumidity || "N/A") + " %"}
                                        </div>
                                    </div>
                                    <div className={css.detailRow}>
                                        <div className={css.detailCell}>
                                            Sun
                                        </div>
                                        <div className={css.detailCell + " " + css.infoBox}>
                                            {(weatherData.currentSolar === undefined ? "N/A" : weatherData.currentSolar) + " W"}
                                        </div>
                                    </div>
                                    <div className={css.detailRow}>
                                        <div className={css.detailCell}>
                                            Pressure
                                        </div>
                                        <div className={css.detailCell + " " + css.infoBox}>
                                            {(weatherData.weewxData?.metric.pressure === undefined ? "N/A" : weatherData.weewxData?.metric.pressure.toFixed(1)) + " kPa"}
                                        </div>
                                    </div>
                                    <div className={css.detailRow}>
                                        <div className={css.detailCell}>
                                            Rain Rate
                                        </div>
                                        <div className={css.detailCell + " " + css.infoBox}>
                                            {formatRainRate(weatherData.currentRainRate, weatherData.isMetric, false)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
                {
                    weatherData.extremes !== undefined && (weatherData.extremes.imperial.lastWeek.totalRain > 0 || weatherData.extremes.imperial.lastWeek.maxGust > 20) ?
                    
                    <div className={css.boxHolder}>
                        <div className={css.box}>
                            <div className={css.boxTitle}>
                                Storm Data
                            </div>
                            <div className={css.flexBoxHolder}>
                                <div className={css.flexBoxItem}>
                                    Rain Total:
                                    <div className={css.detailTable}>
                                        <div className={css.detailRow}>
                                            <div className={css.detailCell}>Last 15</div>
                                            <div className={css.detailCellData}>{formatRain(weatherData.extremes.imperial.lastFifteen.totalRain, weatherData.isMetric, true)}</div>
                                        </div>
                                        <div className={css.detailRow}>
                                            <div className={css.detailCell}>Last 1h</div>
                                            <div className={css.detailCellData}>{formatRain(weatherData.extremes.imperial.lastHour.totalRain, weatherData.isMetric, true)}</div>
                                        </div>
                                        <div className={css.detailRow}>
                                            <div className={css.detailCell}>Last 24h</div>
                                            <div className={css.detailCellData}>{formatRain(weatherData.extremes.imperial.lastDay.totalRain, weatherData.isMetric, true)}</div>
                                        </div>
                                        <div className={css.detailRow}>
                                            <div className={css.detailCell}>Last 7d</div>
                                            <div className={css.detailCellData}>{formatRain(weatherData.extremes.imperial.lastWeek.totalRain, weatherData.isMetric, true)}</div>
                                        </div>
                                        <div className={css.detailRow}>
                                            <div className={css.detailCell}>Last 30</div>
                                            <div className={css.detailCellData}>{formatRain(weatherData.extremes.imperial.lastMonth.totalRain, weatherData.isMetric, true)}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className={css.flexBoxItem}>
                                    Max Gust:
                                    <div className={css.detailTable}>
                                        <div className={css.detailRow}>
                                            <div className={css.detailCell}>Last 15</div>
                                            <div className={css.detailCellData}>{formatWind(weatherData.extremes.imperial.lastFifteen.maxGust, weatherData.isMetric, true)}</div>
                                        </div>
                                        <div className={css.detailRow}>
                                            <div className={css.detailCell}>Last 1h</div>
                                            <div className={css.detailCellData}>{formatWind(weatherData.extremes.imperial.lastHour.maxGust, weatherData.isMetric, true)}</div>
                                        </div>
                                        <div className={css.detailRow}>
                                            <div className={css.detailCell}>Last 24h</div>
                                            <div className={css.detailCellData}>{formatWind(weatherData.extremes.imperial.lastDay.maxGust, weatherData.isMetric, true)}</div>
                                        </div>
                                        <div className={css.detailRow}>
                                            <div className={css.detailCell}>Last 7d</div>
                                            <div className={css.detailCellData}>{formatWind(weatherData.extremes.imperial.lastWeek.maxGust, weatherData.isMetric, true)}</div>
                                        </div>
                                        <div className={css.detailRow}>
                                            <div className={css.detailCell}>Last 30</div>
                                            <div className={css.detailCellData}>{formatWind(weatherData.extremes.imperial.lastWeek.maxGust, weatherData.isMetric, true)}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> : null
                }
                {
                    weatherData.solarData !== undefined ?
                    
                    <div className={css.boxHolder}>
                        <div className={css.box}>
                            <div className={css.boxTitle}>
                                Solar
                            </div>
                            <div className={css.detailTable}>
                                <div className={css.detailRow}>
                                    <div className={css.detailCell}>Official Sunrise/Sunset</div>
                                    <div className={css.detailCellData}>{formatSolarEvent(weatherData.solarData?.officialSunrise, weatherData.solarData?.officialSunset)}</div>
                                </div>
                                <div className={css.detailRow}>
                                    <div className={css.detailCell}>Civil Sunrise/Sunset</div>
                                    <div className={css.detailCellData}>{formatSolarEvent(weatherData.solarData.civilSunrise, weatherData.solarData?.civilSunset)}</div>
                                </div>
                                <div className={css.detailRow}>
                                    <div className={css.detailCell}>Naut. Sunrise/Sunset</div>
                                    <div className={css.detailCellData}>{formatSolarEvent(weatherData.solarData?.nauticalSunrise, weatherData.solarData?.nauticalSunset)}</div>
                                </div>
                                <div className={css.detailRow}>
                                    <div className={css.detailCell}>Astro. Sunrise/Sunset</div>
                                    <div className={css.detailCellData}>{formatSolarEvent(weatherData.solarData?.astroSunrise, weatherData.solarData?.astroSunset)}</div>
                                </div>
                                <div className={css.detailRow}>
                                    <div className={css.detailCell}>Day Length</div>
                                    <div className={css.detailCellData}>{formatDayLength(weatherData.solarData)}</div>
                                </div>
                                <div className={css.detailRow}>
                                    <div className={css.detailCell}>Solar Position</div>
                                    <div className={css.detailCellData}>{formatSolarElevation(weatherData.solarData)}</div>
                                </div>
                            </div>
                        </div>
                    </div> : null
                }
                {
                    extremes !== undefined ?
                    
                    <div className={css.boxHolder}>
                        <div className={css.box}>
                            <div className={css.boxTitle}>
                                Extremes
                            </div>

                            Last 15:
                            <div className={css.detailTable}>
                                <div className={css.detailRow}>
                                    <div className={css.detailCell}>Air</div>
                                    <div className={css.detailCellData}>Min</div>
                                    <div className={css.detailCellData}>{formatTemp(extremes.lastFifteen.temperature.min, weatherData.isMetric, true)}</div>
                                    <div className={css.detailCellData}>Avg</div>
                                    <div className={css.detailCellData}>{formatTemp(extremes.lastFifteen.temperature.avg, weatherData.isMetric, true)}</div>
                                    <div className={css.detailCellData}>Max</div>
                                    <div className={css.detailCellData}>{formatTemp(extremes.lastFifteen.temperature.max, weatherData.isMetric, true)}</div>
                                </div>
                                <div className={css.detailRow}>
                                    <div className={css.detailCell}>Feels</div>
                                    <div className={css.detailCellData}>Min</div>
                                    <div className={css.detailCellData}>{formatTemp(extremes.lastFifteen.feelsLike.min, weatherData.isMetric, true)}</div>
                                    <div className={css.detailCellData}>Avg</div>
                                    <div className={css.detailCellData}>{formatTemp(extremes.lastFifteen.feelsLike.avg, weatherData.isMetric, true)}</div>
                                    <div className={css.detailCellData}>Max</div>
                                    <div className={css.detailCellData}>{formatTemp(extremes.lastFifteen.feelsLike.max, weatherData.isMetric, true)}</div>
                                </div>
                                <div className={css.detailRow}>
                                    <div className={css.detailCell}>Wind</div>
                                    <div className={css.detailCellData}>Min</div>
                                    <div className={css.detailCellData}>{formatWind(extremes.lastFifteen.wind.min, weatherData.isMetric, true)}</div>
                                    <div className={css.detailCellData}>Avg</div>
                                    <div className={css.detailCellData}>{formatWind(extremes.lastFifteen.wind.avg, weatherData.isMetric, true)}</div>
                                    <div className={css.detailCellData}>Max</div>
                                    <div className={css.detailCellData}>{formatWind(extremes.lastFifteen.wind.max, weatherData.isMetric, true)}</div>
                                </div>
                                <div className={css.detailRow}>
                                    <div className={css.detailCell}>Wind Gust</div>
                                    <div className={css.detailCellData}></div>
                                    <div className={css.detailCellData}></div>
                                    <div className={css.detailCellData}></div>
                                    <div className={css.detailCellData}></div>
                                    <div className={css.detailCellData}>Max</div>
                                    <div className={css.detailCellData}>{formatWind(extremes.lastFifteen.maxGust, weatherData.isMetric, true)}</div>
                                </div>
                                <div className={css.detailRow}>
                                    <div className={css.detailCell}>Rain</div>
                                    <div className={css.detailCellData}></div>
                                    <div className={css.detailCellData}></div>
                                    <div className={css.detailCellData}></div>
                                    <div className={css.detailCellData}></div>
                                    <div className={css.detailCellData}>Tot</div>
                                    <div className={css.detailCellData}>{formatRain(extremes.lastFifteen.totalRain, weatherData.isMetric, true)}</div>
                                </div>
                            </div>
                            <hr style={{border: border({width: px(1), color: "black", style: "solid"})}}/>
                            Last Hour:
                            <div className={css.detailTable}>
                                <div className={css.detailRow}>
                                    <div className={css.detailCell}>Air</div>
                                    <div className={css.detailCellData}>Min</div>
                                    <div className={css.detailCellData}>{formatTemp(extremes.lastHour.temperature.min, weatherData.isMetric, true)}</div>
                                    <div className={css.detailCellData}>Avg</div>
                                    <div className={css.detailCellData}>{formatTemp(extremes.lastHour.temperature.avg, weatherData.isMetric, true)}</div>
                                    <div className={css.detailCellData}>Max</div>
                                    <div className={css.detailCellData}>{formatTemp(extremes.lastHour.temperature.max, weatherData.isMetric, true)}</div>
                                </div>
                                <div className={css.detailRow}>
                                    <div className={css.detailCell}>Feels</div>
                                    <div className={css.detailCellData}>Min</div>
                                    <div className={css.detailCellData}>{formatTemp(extremes.lastHour.feelsLike.min, weatherData.isMetric, true)}</div>
                                    <div className={css.detailCellData}>Avg</div>
                                    <div className={css.detailCellData}>{formatTemp(extremes.lastHour.feelsLike.avg, weatherData.isMetric, true)}</div>
                                    <div className={css.detailCellData}>Max</div>
                                    <div className={css.detailCellData}>{formatTemp(extremes.lastHour.feelsLike.max, weatherData.isMetric, true)}</div>
                                </div>
                                <div className={css.detailRow}>
                                    <div className={css.detailCell}>Wind</div>
                                    <div className={css.detailCellData}>Min</div>
                                    <div className={css.detailCellData}>{formatWind(extremes.lastHour.wind.min, weatherData.isMetric, true)}</div>
                                    <div className={css.detailCellData}>Avg</div>
                                    <div className={css.detailCellData}>{formatWind(extremes.lastHour.wind.avg, weatherData.isMetric, true)}</div>
                                    <div className={css.detailCellData}>Max</div>
                                    <div className={css.detailCellData}>{formatWind(extremes.lastHour.wind.max, weatherData.isMetric, true)}</div>
                                </div>
                                <div className={css.detailRow}>
                                    <div className={css.detailCell}>Wind Gust</div>
                                    <div className={css.detailCellData}></div>
                                    <div className={css.detailCellData}></div>
                                    <div className={css.detailCellData}></div>
                                    <div className={css.detailCellData}></div>
                                    <div className={css.detailCellData}>Max</div>
                                    <div className={css.detailCellData}>{formatWind(extremes.lastHour.maxGust, weatherData.isMetric, true)}</div>
                                </div>
                                <div className={css.detailRow}>
                                    <div className={css.detailCell}>Rain</div>
                                    <div className={css.detailCellData}></div>
                                    <div className={css.detailCellData}></div>
                                    <div className={css.detailCellData}></div>
                                    <div className={css.detailCellData}></div>
                                    <div className={css.detailCellData}>Tot</div>
                                    <div className={css.detailCellData}>{formatRain(extremes.lastHour.totalRain, weatherData.isMetric, true)}</div>
                                </div>
                            </div>
                            <hr style={{border: border({width: px(1), color: "black", style: "solid"})}}/>
                            Last Day:
                            <div className={css.detailTable}>
                                <div className={css.detailRow}>
                                    <div className={css.detailCell}>Air</div>
                                    <div className={css.detailCellData}>Min</div>
                                    <div className={css.detailCellData}>{formatTemp(extremes.lastDay.temperature.min, weatherData.isMetric, true)}</div>
                                    <div className={css.detailCellData}>Avg</div>
                                    <div className={css.detailCellData}>{formatTemp(extremes.lastDay.temperature.avg, weatherData.isMetric, true)}</div>
                                    <div className={css.detailCellData}>Max</div>
                                    <div className={css.detailCellData}>{formatTemp(extremes.lastDay.temperature.max, weatherData.isMetric, true)}</div>
                                </div>
                                <div className={css.detailRow}>
                                    <div className={css.detailCell}>Feels</div>
                                    <div className={css.detailCellData}>Min</div>
                                    <div className={css.detailCellData}>{formatTemp(extremes.lastDay.feelsLike.min, weatherData.isMetric, true)}</div>
                                    <div className={css.detailCellData}>Avg</div>
                                    <div className={css.detailCellData}>{formatTemp(extremes.lastDay.feelsLike.avg, weatherData.isMetric, true)}</div>
                                    <div className={css.detailCellData}>Max</div>
                                    <div className={css.detailCellData}>{formatTemp(extremes.lastDay.feelsLike.max, weatherData.isMetric, true)}</div>
                                </div>
                                <div className={css.detailRow}>
                                    <div className={css.detailCell}>Wind</div>
                                    <div className={css.detailCellData}>Min</div>
                                    <div className={css.detailCellData}>{formatWind(extremes.lastDay.wind.min, weatherData.isMetric, true)}</div>
                                    <div className={css.detailCellData}>Avg</div>
                                    <div className={css.detailCellData}>{formatWind(extremes.lastDay.wind.avg, weatherData.isMetric, true)}</div>
                                    <div className={css.detailCellData}>Max</div>
                                    <div className={css.detailCellData}>{formatWind(extremes.lastDay.wind.max, weatherData.isMetric, true)}</div>
                                </div>
                                <div className={css.detailRow}>
                                    <div className={css.detailCell}>Wind Gust</div>
                                    <div className={css.detailCellData}></div>
                                    <div className={css.detailCellData}></div>
                                    <div className={css.detailCellData}></div>
                                    <div className={css.detailCellData}></div>
                                    <div className={css.detailCellData}>Max</div>
                                    <div className={css.detailCellData}>{formatWind(extremes.lastDay.maxGust, weatherData.isMetric, true)}</div>
                                </div>
                                <div className={css.detailRow}>
                                    <div className={css.detailCell}>Rain</div>
                                    <div className={css.detailCellData}></div>
                                    <div className={css.detailCellData}></div>
                                    <div className={css.detailCellData}></div>
                                    <div className={css.detailCellData}></div>
                                    <div className={css.detailCellData}>Tot</div>
                                    <div className={css.detailCellData}>{formatRain(extremes.lastDay.totalRain, weatherData.isMetric, true)}</div>
                                </div>
                            </div>
                            <hr style={{border: border({width: px(1), color: "black", style: "solid"})}}/>
                            Last Week:
                            <div className={css.detailTable}>
                                <div className={css.detailRow}>
                                    <div className={css.detailCell}>Air</div>
                                    <div className={css.detailCellData}>Min</div>
                                    <div className={css.detailCellData}>{formatTemp(extremes.lastWeek.temperature.min, weatherData.isMetric, true)}</div>
                                    <div className={css.detailCellData}>Avg</div>
                                    <div className={css.detailCellData}>{formatTemp(extremes.lastWeek.temperature.avg, weatherData.isMetric, true)}</div>
                                    <div className={css.detailCellData}>Max</div>
                                    <div className={css.detailCellData}>{formatTemp(extremes.lastWeek.temperature.max, weatherData.isMetric, true)}</div>
                                </div>
                                <div className={css.detailRow}>
                                    <div className={css.detailCell}>Feels</div>
                                    <div className={css.detailCellData}>Min</div>
                                    <div className={css.detailCellData}>{formatTemp(extremes.lastWeek.feelsLike.min, weatherData.isMetric, true)}</div>
                                    <div className={css.detailCellData}>Avg</div>
                                    <div className={css.detailCellData}>{formatTemp(extremes.lastWeek.feelsLike.avg, weatherData.isMetric, true)}</div>
                                    <div className={css.detailCellData}>Max</div>
                                    <div className={css.detailCellData}>{formatTemp(extremes.lastWeek.feelsLike.max, weatherData.isMetric, true)}</div>
                                </div>
                                <div className={css.detailRow}>
                                    <div className={css.detailCell}>Wind</div>
                                    <div className={css.detailCellData}>Min</div>
                                    <div className={css.detailCellData}>{formatWind(extremes.lastWeek.wind.min, weatherData.isMetric, true)}</div>
                                    <div className={css.detailCellData}>Avg</div>
                                    <div className={css.detailCellData}>{formatWind(extremes.lastWeek.wind.avg, weatherData.isMetric, true)}</div>
                                    <div className={css.detailCellData}>Max</div>
                                    <div className={css.detailCellData}>{formatWind(extremes.lastWeek.wind.max, weatherData.isMetric, true)}</div>
                                </div>
                                <div className={css.detailRow}>
                                    <div className={css.detailCell}>Wind Gust</div>
                                    <div className={css.detailCellData}></div>
                                    <div className={css.detailCellData}></div>
                                    <div className={css.detailCellData}></div>
                                    <div className={css.detailCellData}></div>
                                    <div className={css.detailCellData}>Max</div>
                                    <div className={css.detailCellData}>{formatWind(extremes.lastWeek.maxGust, weatherData.isMetric, true)}</div>
                                </div>

                                <div className={css.detailRow}>
                                    <div className={css.detailCell}>Rain</div>
                                    <div className={css.detailCellData}></div>
                                    <div className={css.detailCellData}></div>
                                    <div className={css.detailCellData}></div>
                                    <div className={css.detailCellData}></div>
                                    <div className={css.detailCellData}>Tot</div>
                                    <div className={css.detailCellData}>{formatRain(extremes.lastWeek.totalRain, weatherData.isMetric, true)}</div>
                                </div>
                            </div>
                            <hr style={{border: border({width: px(1), color: "black", style: "solid"})}}/>
                            Last Month:
                            <div className={css.detailTable}>
                                <div className={css.detailRow}>
                                    <div className={css.detailCell}>Air</div>
                                    <div className={css.detailCellData}>Min</div>
                                    <div className={css.detailCellData}>{formatTemp(extremes.lastMonth.temperature.min, weatherData.isMetric, true)}</div>
                                    <div className={css.detailCellData}>Avg</div>
                                    <div className={css.detailCellData}>{formatTemp(extremes.lastMonth.temperature.avg, weatherData.isMetric, true)}</div>
                                    <div className={css.detailCellData}>Max</div>
                                    <div className={css.detailCellData}>{formatTemp(extremes.lastMonth.temperature.max, weatherData.isMetric, true)}</div>
                                </div>
                                <div className={css.detailRow}>
                                    <div className={css.detailCell}>Feels</div>
                                    <div className={css.detailCellData}>Min</div>
                                    <div className={css.detailCellData}>{formatTemp(extremes.lastMonth.feelsLike.min, weatherData.isMetric, true)}</div>
                                    <div className={css.detailCellData}>Avg</div>
                                    <div className={css.detailCellData}>{formatTemp(extremes.lastMonth.feelsLike.avg, weatherData.isMetric, true)}</div>
                                    <div className={css.detailCellData}>Max</div>
                                    <div className={css.detailCellData}>{formatTemp(extremes.lastMonth.feelsLike.max, weatherData.isMetric, true)}</div>
                                </div>
                                <div className={css.detailRow}>
                                    <div className={css.detailCell}>Wind</div>
                                    <div className={css.detailCellData}>Min</div>
                                    <div className={css.detailCellData}>{formatWind(extremes.lastMonth.wind.min, weatherData.isMetric, true)}</div>
                                    <div className={css.detailCellData}>Avg</div>
                                    <div className={css.detailCellData}>{formatWind(extremes.lastMonth.wind.avg, weatherData.isMetric, true)}</div>
                                    <div className={css.detailCellData}>Max</div>
                                    <div className={css.detailCellData}>{formatWind(extremes.lastMonth.wind.max, weatherData.isMetric, true)}</div>
                                </div>
                                <div className={css.detailRow}>
                                    <div className={css.detailCell}>Wind Gust</div>
                                    <div className={css.detailCellData}></div>
                                    <div className={css.detailCellData}></div>
                                    <div className={css.detailCellData}></div>
                                    <div className={css.detailCellData}></div>
                                    <div className={css.detailCellData}>Max</div>
                                    <div className={css.detailCellData}>{formatWind(extremes.lastMonth.maxGust, weatherData.isMetric, true)}</div>
                                </div>

                                <div className={css.detailRow}>
                                    <div className={css.detailCell}>Rain</div>
                                    <div className={css.detailCellData}></div>
                                    <div className={css.detailCellData}></div>
                                    <div className={css.detailCellData}></div>
                                    <div className={css.detailCellData}></div>
                                    <div className={css.detailCellData}>Tot</div>
                                    <div className={css.detailCellData}>{formatRain(extremes.lastMonth.totalRain, weatherData.isMetric, true)}</div>
                                </div>
                            </div>
                        </div>
                    </div> : null
                }
            </div>
        )
    }
import { Alert, APIImpl, GetExtremesDataOut, GetLoopinDataOut, GetSolarDataOut, GetWeatherDataOut } from "../wxsite"

type tokenGetter = () => string
type apiGetter = () => APIImpl

export class WeatherAPI {
    tokenGetterFunc: tokenGetter
    apiGetterFunc: apiGetter

    constructor(tokeng: tokenGetter, apig: apiGetter) {
        this.tokenGetterFunc = tokeng
        this.apiGetterFunc = apig
    }

    getAlertingEnabled(): Promise<boolean> {
        const 
            token = this.tokenGetterFunc(),
            promise = new Promise<boolean>((acc, rej) => {
                this.apiGetterFunc().alertingEnabled({
                    token: token,
                }).then((data) => {
                    acc(data.enabled)
                }).catch((e) => {
                    acc(false)
                })
            })
        return promise
    }

    getActiveAlerts(): Promise<Alert[]> {
        const 
            token = this.tokenGetterFunc(),
            promise = new Promise<Alert[]>((acc, rej) => {
                this.apiGetterFunc().getActiveAlerts({
                    token: token,
                }).then((data) => {
                    if (data.ok) {
                        acc(data.alerts)
                    }
                    rej("not ok")
                }).catch((e) => {
                    rej(e)
                })
            })
        return promise
    }

    getWeatherData(unit: string): Promise<GetWeatherDataOut> {
        const 
            token = this.tokenGetterFunc(),
            promise = new Promise<GetWeatherDataOut>((acc, rej) => {
                this.apiGetterFunc().getWeatherData({
                    token: token,
                    unit: unit
                }).then((data) => {
                    acc(data)
                }).catch((e) => {
                    rej(e)
                })
            })
        return promise
    }

    getExtremesData(unit: string): Promise<GetExtremesDataOut> {
        const
            token = this.tokenGetterFunc(),
            promise = new Promise<GetExtremesDataOut>((acc, rej) => {
                this.apiGetterFunc().getExtremes({
                    token: token,
                    unit: unit
                }).then((data) => {
                    acc(data)
                }).catch((e) => {
                    rej(e)
                })
            })
        return promise
    }

    getSolarData(): Promise<GetSolarDataOut> {
        const
            token = this.tokenGetterFunc(),
            promise = new Promise<GetSolarDataOut>((acc, rej) => {
                this.apiGetterFunc().getSolarData({
                    token: token
                }).then((data) => {
                    acc(data)
                }).catch((e) => {
                    rej(e)
                })
            })
        return promise
    }

    getLoopinData(): Promise<GetLoopinDataOut> {
        const
            token = this.tokenGetterFunc(),
            promise = new Promise<GetLoopinDataOut>((acc, rej) => {
                this.apiGetterFunc().getLoopinData({
                    token: token,
                    unit: "f",
                }).then((data) => {
                    acc(data)
                }).catch((e) => {
                    rej(e)
                })
            })
        return promise
    }
}
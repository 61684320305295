import dayjs from "dayjs"
import { WeewxProtobufData } from "../comm/packets"
import { GetSolarDataOut } from "../wxsite"

export const   
    FtoC = (value: number | undefined): (number | undefined) => {
        if (value === undefined) {
            return undefined
        }
        return ((value - 32) * 0.55555555)
    },
    InchestoMM = (value: number | undefined): (number | undefined) => {
        if (value === undefined) {
            return undefined
        }
        return value * 25.4
    },
    MPHtoKPH = (value: number | undefined): (number | undefined) => {
        if (value === undefined) {
            return undefined
        }
        return value * 1.609
    },
    FormatFtoC = (value: number | undefined) => {
        const r = FtoC(value)
        if (r === undefined) {
            return undefined
        }
        return r.toFixed(1)
    },
    getBackground = (weewxData: WeewxProtobufData): string => {
        const radiation = weewxData.imperial.rad
    
        if (radiation === 0) {
            return "linear-gradient( to top, #335, #111)"
        } else if (radiation < 50) {
            return "linear-gradient( to top, #DDD, #111)"
        } else if (radiation < 100) {
            return "linear-gradient( to top, #EEE, #222)"
        } else if (radiation < 200) {
            return "linear-gradient( to top, #EEE, #556)"
        } else if (radiation < 400) {
            return "linear-gradient( to top, #EEE, #668)"
        } else if (radiation < 500) {
            return "linear-gradient( to top, #BBE, #66F)"
        } else if (radiation < 2000) {
            return "linear-gradient( to top, #AAE, #44F)"
        }
    
    
        return "linear-gradient( to top, #DDD, #111)"
    }

export const formatEpochDelta = (epoch?: Date): string => {
    if (epoch === undefined) {
        return "N/A"
    }
    const delta = new Date().getTime() - epoch.getTime()
    
    if (delta < 1000) {
        return "now";
    } else if (delta < 60000) {
        return (delta/1000).toFixed(0) + "s ago"
    } else {
        const seconds = delta/1000
        if (seconds < 90) {
            return seconds.toFixed(1) + "s ago"
        } else {
            const minutes = seconds / 60
            return minutes.toFixed(1) + "m ago"
        }
    }
}

export const formatSolarElevation = (solarData?: GetSolarDataOut): string => {
    if (solarData === undefined) {
        return "N/A"
    }
    return "" + solarData.solarElevation.toFixed(1) + "° ⇵, " + solarData.solarAzimuth.toFixed(1) + "° ⇄"
}

export const formatSolarEvent = (start?: number, end?: number): string => {
    if (start === undefined || end === undefined) {
        return "N/A"
    }
    const 
        startDate = dayjs(new Date(start * 1000)),
        endDate = dayjs(new Date(end * 1000))

    return "" + startDate.format("h:mm a") + " / " + endDate.format("h:mm a")
}

export const suffixFormatter = (data: (number | undefined), precision: number, suffix: string): string => {
    if (data === undefined) {
        return "N/A"
    }
    return "" + data.toFixed(precision) + suffix
}

export const humanReadableTimeDelta = (delta: number): string => {
    if (delta < 60) {
        return "" + delta.toFixed(1) + "s";
    } else if (delta < 3600) {
        let minutes = delta / 60;
        let seconds = delta % 60;

        return "" + Math.floor(minutes) + "m "+Math.round(seconds)+"s";
    } else {
        var hours = Math.floor(delta / 3600);
        var remainer_seconds = delta % 3600;
        var minutes = Math.round(remainer_seconds / 60);
        return "" + hours + "h "+minutes+"m"
    }
}
export const formatDayLength = (solarData: (GetSolarDataOut | undefined)): string => {
    if (solarData === undefined) {
        return "N/A"
    }
    return humanReadableTimeDelta(solarData.officialSunset - solarData.officialSunrise)
}
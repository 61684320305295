/* tslint:disable */

// This file has been generated by https://github.com/h2oai/protoc-gen-twirp_ts.
// Do not edit.
import {
  createTwirpRequest,
  Fetch,
  throwTwirpError
} from './twirp'


export interface AlertingEnabledIn {
}

export interface AlertingEnabledOut {
  enabled: boolean
  
}

export interface GetActiveAlertsIn {
}

export interface GetActiveAlertsOut {
  ok: boolean
  alerts: Alert[]
  
}

export interface Alert {
  id: string
  systemid: string
  idhash: string
  sender: string
  issued: number
  processed: number
  effective: number
  expiry: number
  event: string
  title: string
  description: string
  instruction: string
  areanames: string[]
  geocodes: string[]
  sendername: string
  
}

export interface GetWeatherDataIn {
  token: string
  unit: string
  
}

export interface GetWeatherDataOut {
  token: string
  unit: string
  epoch: number
  outsideTemp: number
  outsideHumidity: number
  feelsLike: number
  humidex: number
  windChill: number
  heatIndex: number
  et: number
  uv: number
  rad: number
  rainRate: number
  windSpeed: number
  windDir: number
  windGust: number
  windGustDir: number
  dewPoint: number
  pressure: number
  
}

export interface GetExtremesDataIn {
  token: string
  unit: string
  
}

export interface GetExtremesDataOut {
  lastFifteen: ExtremeData
  lastHour: ExtremeData
  lastDay: ExtremeData
  lastWeek: ExtremeData
  lastMonth: ExtremeData
  
}

export interface ExtremeData {
  epochStart: number
  epochEnd: number
  temperature: ExtremeSample
  feelsLike: ExtremeSample
  humidex: ExtremeSample
  windchill: ExtremeSample
  wind: ExtremeSample
  totalRain: number
  maxGust: number
  
}

export interface GetLoopinDataIn {
  token: string
  unit: string
  
}

export interface GetLoopinDataOut {
  token: string
  unit: string
  epoch: number
  windDir: number
  dewpoint: number
  outtemp: number
  outhumidity: number
  rainrate: number
  windspeed: number
  windgust: number
  past10windgust: number
  windgustdir: number
  radiation: number
  past10raintotal: number
  
}

export interface ExtremeSample {
  min: number
  max: number
  avg: number
  
}

export interface GetTokenIn {
  currentToken: string
  
}

export interface GetTokenOut {
  newToken: string
  ttl: number
  
}

export interface GetSolarDataIn {
  token: string
  
}

export interface GetSolarDataOut {
  astroSunrise: number
  nauticalSunrise: number
  civilSunrise: number
  officialSunrise: number
  solarTransit: number
  officialSunset: number
  civilSunset: number
  nauticalSunset: number
  astroSunset: number
  solarElevation: number
  solarAzimuth: number
  timeOfDay: string
  
}

export interface WebsocketMessage {
  messageType: string
  messageArgument: string
  messagePayload: string
  tokenData: GetTokenOut
  
}



// Services
export interface API {
  getWeatherData: (data: GetWeatherDataIn, headers?: object) => Promise<GetWeatherDataOut>
  getExtremes: (data: GetExtremesDataIn, headers?: object) => Promise<GetExtremesDataOut>
  getSolarData: (data: GetSolarDataIn, headers?: object) => Promise<GetSolarDataOut>
  getLoopinData: (data: GetLoopinDataIn, headers?: object) => Promise<GetLoopinDataOut>
  getToken: (data: GetTokenIn, headers?: object) => Promise<GetTokenOut>
  getActiveAlerts: (data: GetActiveAlertsIn, headers?: object) => Promise<GetActiveAlertsOut>
  alertingEnabled: (data: AlertingEnabledIn, headers?: object) => Promise<AlertingEnabledOut>
}

export class APIImpl implements API {
  private hostname: string
  protected fetch: Fetch
  private path = '/twirp/wxsite.API/'

  constructor(hostname: string, fetch: Fetch) {
    this.hostname = hostname
    this.fetch = fetch
  }

  protected url(name: string): string {
    return this.hostname + this.path + name
  }

  
  public getWeatherData(params: GetWeatherDataIn, headers: object = {}): Promise<GetWeatherDataOut> {
    return this.fetch(
      this.url('GetWeatherData'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json()
    })
  }
  
  public getExtremes(params: GetExtremesDataIn, headers: object = {}): Promise<GetExtremesDataOut> {
    return this.fetch(
      this.url('GetExtremes'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json()
    })
  }
  
  public getSolarData(params: GetSolarDataIn, headers: object = {}): Promise<GetSolarDataOut> {
    return this.fetch(
      this.url('GetSolarData'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json()
    })
  }
  
  public getLoopinData(params: GetLoopinDataIn, headers: object = {}): Promise<GetLoopinDataOut> {
    return this.fetch(
      this.url('GetLoopinData'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json()
    })
  }
  
  public getToken(params: GetTokenIn, headers: object = {}): Promise<GetTokenOut> {
    return this.fetch(
      this.url('GetToken'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json()
    })
  }
  
  public getActiveAlerts(params: GetActiveAlertsIn, headers: object = {}): Promise<GetActiveAlertsOut> {
    return this.fetch(
      this.url('GetActiveAlerts'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json()
    })
  }
  
  public alertingEnabled(params: AlertingEnabledIn, headers: object = {}): Promise<AlertingEnabledOut> {
    return this.fetch(
      this.url('AlertingEnabled'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json()
    })
  }
  
}
import React from 'react';
import logo from './logo.svg';
import './App.css';

import { style, media, stylesheet, extend } from 'typestyle';
import { border, em, px, background, percent, rgb, padding, rem } from 'csx';
import { WebsocketConnection } from './comm/sock';
import { RootView } from './views/RootView'
import { TokenManager } from './comm/tokenmanager'
import { APIImpl } from './wxsite';
import { WeatherAPI } from './comm/wxapi'

const isDev = process.env.NODE_ENV === 'development';

export const WXSITEAPI = isDev ? new APIImpl("http://localhost:9773", window.fetch.bind(window)) : new APIImpl("",  window.fetch.bind(window))


const tmanager = new TokenManager((currentToken: string) => {
  return WXSITEAPI.getToken({currentToken: currentToken})
})

const App =
  () => {

    const
      [socketConnection, setSocketConnection] = React.useState(undefined as (WebsocketConnection | undefined)),
      [weatherAPI, setWeatherAPI] = React.useState(undefined as (WeatherAPI | undefined))
    
    React.useEffect(() => {
      const sock = new WebsocketConnection((token: string, ttl: number) => {tmanager.updateToken(token, ttl)})
      setSocketConnection(sock)

      return () => {
        sock.close()
        setSocketConnection(undefined)
      }
    }, [])

    const wxApi = new WeatherAPI((): string => {
      return tmanager.getToken() || ""
    }, (): APIImpl => {
      return WXSITEAPI
    })

    return (
      <>
      {
        socketConnection === undefined ? 
        null : <RootView sock={socketConnection} wxapi={wxApi} />
      }
      </>
    );
  }


export default App;

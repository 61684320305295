import { GetTokenOut } from "../wxsite";

export class TokenManager {
    currentToken: (string | undefined)
    tokenExpiry: (Date | undefined)

    constructor(refreshTokenFunc: (currentToken: string) => Promise<GetTokenOut>) {

        setInterval(() => {
            if (this.currentToken !== undefined && this.tokenExpiry !== undefined) {
                const timeToExpiry = this.tokenExpiry.getTime() - new Date().getTime()
                if (timeToExpiry < 60000) {
                    refreshTokenFunc(this.currentToken || "").then((tokenData) => {
                        this.updateToken(tokenData.newToken, tokenData.ttl)
                    }).catch((e) => {
                        this.currentToken = undefined
                    })
                }
            }
        }, 5000)
    }

    updateToken(token: string, ttl: number) {
        this.currentToken = token
        this.tokenExpiry = new Date(new Date().getTime() + ttl*1000);
    }

    getToken(): (string | undefined) {
        return this.currentToken
    }
}